import React from 'react';
import ProcurementList from '../components/ProcurementList/ProcurementList';

function Procurement() {
  return (
    <>
      <ProcurementList />
    </>
  );
}

export default Procurement;
