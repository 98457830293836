// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 575px) {
  .modal__procurement {
    width: 95%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ProcurementList/modals/styles.scss"],"names":[],"mappings":"AACI;EADJ;IAEQ,UAAA;EACN;AACF","sourcesContent":[".modal__procurement {\n    @media(max-width: 575px) {\n        width: 95%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
