// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-scrollable {
  overflow-x: auto;
}

.welders_column {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 4;
  background-color: white !important;
}

.welders_column::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background-color: #dee2e6;
}

.welders_thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/components/WeldersList/modals/style.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEE;EAEC,gBAAA;EACA,OAAA;EACA,MAAA;EACA,UAAA;EACA,kCAAA;AAAH;;AAGC;EACI,WAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;EACA,UAAA;EACA,YAAA;EACA,yBAAA;AAAL;;AAGA;EACI,gBAAA;EACA,MAAA;EACA,UAAA;EACA,kCAAA;AAAJ","sourcesContent":[".table-scrollable {\n    overflow-x: auto;\n  }\n  \n  .welders_column\n  {\n   position: sticky;\n   left: 0;\n   top: 0;\n   z-index: 4;\n   background-color: white !important;\n }\n \n .welders_column::after {\n     content: '';\n     position: absolute;\n     top: 0;\n     right: 0;\n     width: 1px;\n     height: 100%;\n     background-color: #dee2e6; \n   }\n\n.welders_thead {\n    position: sticky;\n    top: 0;\n    z-index: 1;\n    background-color: white !important; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
