import React from 'react';
import HomeContent from '../components/HomeContent/HomeContent';

function WorkingPage() {
  return (
    <>
      <HomeContent />
    </>
  );
}

export default WorkingPage;
