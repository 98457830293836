// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.manufacture_column {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 4;
  background-color: white !important;
}

.manufacture_column::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background-color: #dee2e6;
}

.manufacture_td {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 3;
  background-color: white !important;
}

.manufacture_thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white !important;
}

.highlighted-row {
  background-color: #565656 !important; /* Цвет фона для выделенной строки */
  font-weight: bold; /* Жирный шрифт для текста в выделенной строке */
  /* Другие стили, если необходимо */
  color: white !important;
}

.manufacture_column.highlighted-row {
  background-color: #565656 !important;
  font-weight: bold !important;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/ManufactureList/styles.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAEd;EAEA,gBAAA;EACA,OAAA;EACA,MAAA;EACA,UAAA;EACA,kCAAA;AADF;;AAIA;EACI,WAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;EACA,UAAA;EACA,YAAA;EACA,yBAAA;AADJ;;AAIE;EACE,gBAAA;EACA,OAAA;EACA,MAAA;EACA,UAAA;EACA,kCAAA;AADJ;;AAIM;EACE,gBAAA;EACA,MAAA;EACA,UAAA;EACA,kCAAA;AADR;;AAOE;EACE,oCAAA,EAAA,oCAAA;EACA,iBAAA,EAAA,gDAAA;EACA,kCAAA;EACA,uBAAA;AAJJ;;AAOE;EACE,oCAAA;EACA,4BAAA;EACA,YAAA;AAJJ","sourcesContent":["\n  \n  .manufacture_column\n {\n  position: sticky;\n  left: 0;\n  top: 0;\n  z-index: 4;\n  background-color: white !important;\n}\n\n.manufacture_column::after {\n    content: '';\n    position: absolute;\n    top: 0;\n    right: 0;\n    width: 1px;\n    height: 100%;\n    background-color: #dee2e6; \n  }\n\n  .manufacture_td {\n    position: sticky;\n    left: 0;\n    top: 0;\n    z-index: 3;\n    background-color: white !important; \n      }\n\n      .manufacture_thead {\n        position: sticky;\n        top: 0;\n        z-index: 1;\n        background-color: white !important; \n    }\n    \n    \n  \n\n  .highlighted-row {\n    background-color: #565656 !important; /* Цвет фона для выделенной строки */\n    font-weight: bold; /* Жирный шрифт для текста в выделенной строке */\n    /* Другие стили, если необходимо */\n    color: #ffff !important;\n  }\n\n  .manufacture_column.highlighted-row {\n    background-color: #565656 !important;\n    font-weight: bold !important;\n    color: #ffff;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
