import React from 'react';
import ProductionChangeList from '../components/ProductionChangeList/ProductionChangeList';

function ProductionChange() {
  return (
    <>
      <ProductionChangeList />
    </>
  );
}

export default ProductionChange;
