// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-scrollable {
  overflow-x: auto;
}

.shipment_column {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white !important;
}

.shipment_column::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background-color: #dee2e6;
}`, "",{"version":3,"sources":["webpack://./src/components/ShipmentList/modals/styles.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEE;EAEA,gBAAA;EACA,OAAA;EACA,UAAA;EACA,kCAAA;AAAF;;AAGA;EACI,WAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;EACA,UAAA;EACA,YAAA;EACA,yBAAA;AAAJ","sourcesContent":[".table-scrollable {\n    overflow-x: auto;\n  }\n  \n  .shipment_column\n {\n  position: sticky;\n  left: 0;\n  z-index: 1;\n  background-color: white !important;\n}\n\n.shipment_column::after {\n    content: '';\n    position: absolute;\n    top: 0;\n    right: 0;\n    width: 1px;\n    height: 100%;\n    background-color: #dee2e6; \n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
