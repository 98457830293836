import React from 'react';
import OrderMaterialsList from '../components/OrderMaterialsList/OrderMaterialsList';

function OrderMaterials() {
  return (
    <>
      <OrderMaterialsList />
    </>
  );
}

export default OrderMaterials;
