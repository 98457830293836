import React from 'react';
import HomePage from '../HomePage/HomePageList';

function HomeContent() {
  return (
    <div className="homecontent">
      <HomePage />
    </div>
  );
}

export default HomeContent;
