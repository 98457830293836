import React from 'react';
import Login from '../components/Login/Login';

function Home() {
  return (
    <>
      <Login />
    </>
  );
}

export default Home;
