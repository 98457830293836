// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  margin-top: 25px;
  display: flex;
}
.header__title {
  margin-top: 6px;
  margin-left: 25px;
  font-size: 27px;
  font-weight: 700;
  color: rgb(7, 7, 7);
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .header__title {
    font-size: 27px;
  }
}
@media (max-width: 575px) {
  .header__title {
    font-size: 24px;
  }
}
@media (max-width: 480px) {
  .header__title {
    font-size: 18px;
  }
}
.header__icon {
  width: 90%;
}
@media (max-width: 480px) {
  .header__icon {
    width: 70%;
  }
}
.header__logo {
  cursor: pointer;
}
@media (max-width: 480px) {
  .header__logo {
    width: 90%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.styles.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,aAAA;AACJ;AAAI;EACI,eAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;AAER;AADQ;EAPJ;IAQQ,eAAA;EAIV;AACF;AAHQ;EAVJ;IAWQ,eAAA;EAMV;AACF;AALQ;EAbJ;IAcQ,eAAA;EAQV;AACF;AAJI;EACQ,UAAA;AAMZ;AALY;EAFR;IAGY,UAAA;EAQd;AACF;AANI;EACI,eAAA;AAQR;AAPQ;EAFJ;IAGQ,UAAA;EAUV;AACF","sourcesContent":[".header {\n    margin-top: 25px;\n    display: flex;\n    &__title {\n        margin-top: 6px;\n        margin-left: 25px;\n        font-size: 27px;\n        font-weight: 700;\n        color: rgb(7, 7, 7);\n        text-transform: uppercase;\n        @media (max-width: 768px) {\n            font-size: 27px;\n        }\n        @media(max-width: 575px) {\n            font-size: 24px;\n        }\n        @media(max-width: 480px) {\n            font-size: 18px;\n        }\n        \n    }\n\n    &__icon {\n            width: 90%;\n            @media(max-width: 480px) {\n                width: 70%;\n            }\n    }\n    &__logo {\n        cursor: pointer;\n        @media(max-width: 480px) {\n            width: 90%;\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
