// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.estimate {
  display: flex;
  justify-content: center;
}
.estimate__brigade {
  margin-top: 15px;
}
.estimate__brigade-title {
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}
.estimate__input {
  border: none;
}
.estimate__table-sum {
  margin-top: 15px;
  margin-bottom: 0px !important;
}
.estimate-brigade {
  margin-top: 25px;
}
.estimate-brigade__title {
  font-size: 21px;
  font-weight: 500;
}
@media (max-width: 460px) {
  .estimate-brigade__title {
    font-size: 18px;
  }
}
.estimate-brigade__content {
  margin-top: 15px;
}
.estimate-brigade__name {
  font-size: 20px;
  font-weight: 500;
}
@media (max-width: 460px) {
  .estimate-brigade__name {
    font-size: 17px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Estimate/style.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;AACJ;AAGI;EACI,gBAAA;AADR;AAGQ;EACI,eAAA;EACA,gBAAA;EACA,eAAA;AADZ;AAKI;EACI,YAAA;AAHR;AAMI;EACI,gBAAA;EACA,6BAAA;AAJR;AAQI;EACI,gBAAA;AANR;AAQQ;EACI,eAAA;EACA,gBAAA;AANZ;AAOY;EAHJ;IAIQ,eAAA;EAJd;AACF;AAOQ;EACI,gBAAA;AALZ;AAQQ;EACI,eAAA;EACA,gBAAA;AANZ;AAOY;EAHJ;IAIQ,eAAA;EAJd;AACF","sourcesContent":[".estimate {\n    display: flex;\n    justify-content: center;\n\n   \n\n    &__brigade {\n        margin-top: 15px;\n\n        &-title {\n            font-size: 18px;\n            font-weight: 500;\n            cursor: pointer;\n        }\n    }\n\n    &__input {\n        border: none;\n    }\n\n    &__table-sum {\n        margin-top: 15px;\n        margin-bottom: 0px !important;\n    }\n\n\n    &-brigade {\n        margin-top: 25px;\n\n        &__title {\n            font-size: 21px;\n            font-weight: 500;\n            @media(max-width: 460px){\n                font-size: 18px;\n            }\n        }\n\n        &__content {\n            margin-top: 15px;\n        }\n\n        &__name {\n            font-size: 20px;\n            font-weight: 500;\n            @media(max-width: 460px) {\n                font-size: 17px;\n            }\n        }\n    }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
