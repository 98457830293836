// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar-brigade__filter {
  margin-top: 15px;
  display: flex;
}
.calendar-brigade__filter-item {
  font-size: 19px;
  font-weight: 500;
  color: rgb(7, 7, 7);
  padding-right: 10px;
  cursor: pointer;
}
.calendar-brigade__filter-item:active {
  color: #ff0000;
}
.calendar-brigade__filter-item.active {
  color: #ff0000;
}
.calendar-brigade__table {
  width: 50%;
}
@media (max-width: 460px) {
  .calendar-brigade__table {
    width: 100%;
  }
}
.calendar-brigade__month {
  margin-top: 15px;
  display: flex;
}
.calendar-brigade__month-arrow img {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.calendar-brigade__month-name {
  font-size: 18px;
  font-weight: 400;
  padding: 0px 7px 0px 7px;
}`, "",{"version":3,"sources":["webpack://./src/components/ChangeBrigade/style.scss"],"names":[],"mappings":"AAEI;EACI,gBAAA;EACA,aAAA;AADR;AAGQ;EACI,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;AADZ;AAEY;EACI,cAAA;AAAhB;AAGY;EACI,cAAA;AADhB;AAMI;EAEI,UAAA;AALR;AAMQ;EAHJ;IAIQ,WAAA;EAHV;AACF;AAMI;EACI,gBAAA;EACA,aAAA;AAJR;AAOY;EACI,WAAA;EACA,YAAA;EACA,eAAA;AALhB;AASQ;EACI,eAAA;EACA,gBAAA;EACA,wBAAA;AAPZ","sourcesContent":[".calendar-brigade {\n   \n    &__filter {\n        margin-top: 15px;\n        display: flex;\n\n        &-item {\n            font-size: 19px;\n            font-weight: 500;\n            color: rgb(7,7,7);\n            padding-right: 10px;\n            cursor: pointer;\n            &:active {\n                color: #ff0000;\n               \n              }\n            &.active {\n                color: #ff0000;\n            }\n        }\n    }\n\n    &__table {\n        \n        width: 50%;\n        @media(max-width: 460px) {\n            width: 100%;\n        }\n    }\n\n    &__month {\n        margin-top: 15px;\n        display: flex;\n\n        &-arrow {\n            img {\n                width: 25px;\n                height: 25px;\n                cursor: pointer;\n            }\n        }\n\n        &-name {\n            font-size: 18px;\n            font-weight: 400;\n            padding: 0px 7px 0px 7px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
